<template>
	<v-text-field
		ref="search"
		v-model.trim="listingSearch"
		:background-color="!isFocused ? 'grey lighten-3' : undefined"
		:placeholder="placeholder"
		autocomplete="off"
		hide-details
		outlined
		style="max-width: 450px; min-width: 400px"
		@blur="resetSearch"
		@focus="isFocused = true"
		@keydown.esc="onEsc"
		@keydown.enter="onEnter"
		@keydown.tab="onTab"
		v-if="allowShow"
	>
		<template #prepend-inner>
			<v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
		</template>
	</v-text-field>
</template>

<script>
//import { SearchEventBus } from "@/core/lib/search.lib";
import { isEmpty } from "lodash";
import { mapGetters } from "vuex";
import { SET_SEARCH_MODEL } from "@/core/services/store/listing.module";

export default {
	data: () => ({
		isFocused: false,
		listingSearch: null,
		allowShow: true,
	}),
	methods: {
		onEsc() {
			this.resetSearch();
		},
		onEnter() {
			this.startSearch();
		},
		onTab() {
			this.startSearch();
		},
		startSearch() {
			this.$store.dispatch(SET_SEARCH_MODEL, this.listingSearch);
			//SearchEventBus.$emit("start-search", this.listingSearch);
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.listingSearch = null;
				_this.isFocused = false;
			});
		},
	},
	beforeDestroy() {
		document.onkeydown = null;
		this.resetSearch();
		this.$store.dispatch(SET_SEARCH_MODEL, null);
		// SearchEventBus.$off("search-template");
	},
	beforeCreate() {
		//this.resetSearch();
	},
	watch: {
		"$route.name": {
			handler: function (search) {
				if (search != "undefined") {
					this.allowShow = search == "contract-detail" || search == "project-detail" ? false : true;
				}
			},
			deep: true,
			immediate: true,
		},
	},
	mounted() {
		const _this = this;
		//_this.$route?.name=='contract-detail'||_this.$route?.name=='project-detail'
		// this.listingSearch = _this.$route.query.search;
		if (isEmpty(_this.$route.query) === false && _this.$route.query.search) {
			_this.listingSearch = _this.$route.query.search;
			_this.startSearch();
			_this.$refs.search.focus();
		}
	},
	computed: {
		...mapGetters(["searchTitle"]),
		placeholder() {
			return this.isFocused ? "Start Typing then press enter" : "Search " + this.searchTitle + "...";
		},
		isSearching() {
			return this.listingSearch && this.listingSearch.length > 0;
		},
	},
};
</script>
